<template>
  <div>
    <lf-h3>{{ offerGroup?.[stageType]?.title }}</lf-h3>
    <div class="grid grid-cols-2 gap-3 px-6 pt-6">
      <wfl-offer-field
        v-for="field in offerFields"
        :key="field.id"
        :name="field.name"
        :title="field.label ?? ''"
        :type="field.type"
        :options="field.options"
        :view-only="viewOnly || field.foreign || field.readonly"
      />
    </div>
  </div>
</template>
<script setup lang="ts">
import { type FieldGroup } from "@/models/offers";
import { computed } from "vue";
import WflOfferField from "@/views/deals/components/WflOfferField.vue";

const props = defineProps<{
  offerGroup: FieldGroup;
  stageType: "closing" | "offer";
  viewOnly: boolean;
}>();

const offerFields = computed(() =>
  props.offerGroup.fields
    .map((field) => ({
      id: field.id,
      name: field.name,
      type: field.type,
      options: field.options,
      label: field[props.stageType]?.label,
      visibility: field[props.stageType]?.visibility,
      foreign: !!field.foreign_via,
      readonly: !!field[props.stageType]?.readonly
    }))
    .filter((field) => field.visibility?.workflow_live)
);
</script>
